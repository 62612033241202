import { Field } from "react-final-form";
import { questionnaireNewsletterFormsPositionsConfig } from "../../../../../config";
import { MobilePositionsVariantProps } from "./MobilePositionsVariant.props";
import {
  Button,
  ButtonWrapper,
  PositionsWrapper,
  Wrapper,
  WrapperGraphics
} from "./MobilePositionsVariant.styles";

export const MobilePositionsVariant = ({ availablePositions }: MobilePositionsVariantProps) => {
  return (
    <Wrapper>
      <WrapperGraphics />
      <PositionsWrapper>
        {questionnaireNewsletterFormsPositionsConfig.map((position) => (
          <ButtonWrapper key={`mobile.${position}`}>
            <Field
              name={`mobile.${position}`}
              render={({ input: { value, onChange } }) =>
                availablePositions[position] ? (
                  <Button selected={value} onClick={() => onChange(!value)} />
                ) : null
              }
            />
          </ButtonWrapper>
        ))}
      </PositionsWrapper>
    </Wrapper>
  );
};
