import { styled, Box as MuiBox, Typography as MuiTypography } from "@mui/material";

export const SliderWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      width: auto;
      margin: 0 14px;

      ${breakpoints.up("md")} {
        width: 455px;
        margin: 0 auto;
      }
    }
  `
);

export const HintText = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body2 },
      breakpoints
    }
  }) => `
    &.MuiTypography-root {
      color: ${custom.white.main};
      font-size: 14px;
      line-height: 21px;
      font-weight: ${body2.desktop.fontWeight};
      text-align: center;
      margin: 15px 0 0;

      ${breakpoints.up("md")} {
        margin: 15px 70px 0;
      }
    }
  `
);
