import { useCallback, useMemo } from "react";
import { QuestionnaireNewsletterFormsPositionsStepData, QuestionnaireStepType } from "api/models";
import { FormProps } from "react-final-form";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { putSubmitNewsletterFormsPositions } from "../../api";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { useStepsResolver } from "../useStepsResolver";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";
import {
  NEWSLETTER_FORMS_POSITIONS,
  parseNewsletterFormsPositionsFormMetadata
} from "./useNewsletterFormsPositionsStep.helpers";

export const useNewsletterFormsPositionsStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.NEWSLETTER_FORMS_POSITIONS);

  const data = useMemo(() => stepData?.data ?? NEWSLETTER_FORMS_POSITIONS, [stepData?.data]);

  const metadata = useMemo(
    () => parseNewsletterFormsPositionsFormMetadata(stepData?.metadata),
    [stepData?.metadata]
  );

  const submitNewsletterFormsPositions =
    useQuestionnaireSubmitMutation<QuestionnaireNewsletterFormsPositionsStepData>(
      putSubmitNewsletterFormsPositions
    );

  const executeSubmitNewsletterFormsPositions: FormProps<
    QuestionnaireNewsletterFormsPositionsStepData,
    QuestionnaireNewsletterFormsPositionsStepData
  >["onSubmit"] = useCallback(
    async ({ desktop, mobile }) => {
      try {
        await submitNewsletterFormsPositions({
          desktop,
          mobile
        });
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitNewsletterFormsPositions]
  );

  return {
    data,
    metadata,
    hasPrev: !!prevStep,
    handlePrevStep,
    executeSubmitNewsletterFormsPositions
  };
};
