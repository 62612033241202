import { FORM_ERROR, ValidationErrors } from "final-form";
import { QuestionnaireNewsletterFormsPositionsStepData } from "api/models";
import { TemplatePosition } from "shared/types";
import { QuestionnaireNewsletterFormsPositionsFormMetadataState } from "../../../types";

export const newsletterFormsPositionsValidator =
  (availablePositions: QuestionnaireNewsletterFormsPositionsFormMetadataState) =>
  (values: QuestionnaireNewsletterFormsPositionsStepData): ValidationErrors => {
    let result: ValidationErrors = {};

    const desktopSelected = Object.entries(availablePositions.desktop).some(
      ([key, value]) => value && values.desktop[key as TemplatePosition]
    );

    const mobileSelected = Object.entries(availablePositions.mobile).some(
      ([key, value]) => value && values.mobile[key as TemplatePosition]
    );

    if (!desktopSelected && !mobileSelected) {
      result = {
        ...result,
        [FORM_ERROR]: "questionnaireModule.errors.atLeastOnePositionSelected"
      };
    }

    return result;
  };
