import { styled, Box as MuiBox, IconButton as MuiIconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { ReactComponent as MobileGraphics } from "assets/icons/mobile_wrapper.svg";
import { MobileButtonProps } from "./MobilePositionsVariant.props";

export const Wrapper = styled(MuiBox)(() => {
  return `
    &.MuiBox-root {
      position: relative;
      width: 246px;
      height: 490px;
      margin: 0 auto;
    }
  `;
});

export const WrapperGraphics = styled(MobileGraphics)(
  () => `
    top: 0;
    left: 0;
    width: 246px;
    height: 490px;
    position: absolute;
    pointer-events: none;
    z-index: 2;
  `
);

export const PositionsWrapper = styled(MuiBox)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiBox-root {
      position: absolute;
      margin: 0;
      padding: 24px 8px 12px;
      width: 214px;
      height: 460px;
      top: 16px;
      left: 16px;
      display: flex;
      flex-flow: wrap;
      gap: 148px 36px;
      border-radius: 20px;
      background-color: ${custom.background};
      z-index: 1;
    }
  `
);

export const ButtonWrapper = styled(MuiBox)(() => {
  return `
    &.MuiBox-root {
      width: 42px;
      height: 42px;
    }
  `;
});

export const Button = styled(({ selected, ...props }: MobileButtonProps) => {
  return <MuiIconButton {...props}>{selected && <CheckIcon />}</MuiIconButton>;
})(
  ({
    theme: {
      palette: { custom }
    },
    selected
  }) => {
    const backgroundColor = selected ? custom.turquoise.main : "transparent";

    return `
      &.MuiIconButton-root {
        width: 42px;
        min-width: 42px;
        height: 42px;
        color: ${custom.white.main};
        text-transform: none;
        background-color: ${backgroundColor};
        border: 2px solid ${custom.turquoise.light};
        border-radius: 50%;
        padding: 0;
        margin: 0;

        &:hover {
          background-color: ${custom.turquoise.main};
          border-color: ${custom.turquoise.main};
        }
      }
    `;
  }
);
