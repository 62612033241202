import { ActivationPopupsSensitivity } from "api/models";
import { SENSITIVITY_TYPES } from "config/constants";

export const parseSensitivityToFormValue = (sensitivity: ActivationPopupsSensitivity): number => {
  const fraction = 100 / (SENSITIVITY_TYPES.length - 1);
  const index = SENSITIVITY_TYPES.findIndex((type) => type === sensitivity);

  return index * fraction;
};

export const parseFormValueToSensitivity = (value: number): ActivationPopupsSensitivity => {
  const fraction = 100 / (SENSITIVITY_TYPES.length - 1);
  const index = Math.floor(value / fraction);

  return SENSITIVITY_TYPES[index];
};
