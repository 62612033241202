import { styled, Box as MuiBox } from "@mui/material";

export const Wrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      text-align: center;
      margin-bottom: 16px;
    }
  `
);
