import { useMemo } from "react";
import { PositionsCounterProps } from "./PositionsCounter.props";
import { Wrapper } from "./PositionsCounter.styles";
import { questionnaireNewsletterFormsPositionsConfig } from "../../../../config";

export const PositionsCounter = ({ positions, availablePositions }: PositionsCounterProps) => {
  const availablePositionsKeys = useMemo(
    () => questionnaireNewsletterFormsPositionsConfig.filter((field) => availablePositions[field]),
    [availablePositions]
  );

  const selectedPositionsKeys = useMemo(
    () => availablePositionsKeys.filter((key) => positions[key]),
    [positions, availablePositionsKeys]
  );

  return (
    <Wrapper>
      {selectedPositionsKeys.length} / {availablePositionsKeys.length}
    </Wrapper>
  );
};
