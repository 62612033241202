import { apiClient } from "shared/utils";
import { GetSensitivityResponse } from "api/models";

export const getSensitivity = async (shopId: number) => {
  const { data } = await apiClient.get<GetSensitivityResponse>(
    `${shopId}/activation-popups/sensitivity`
  );

  return data;
};
