export enum TemplatePosition {
  TOP_LEFT = "topLeft",
  TOP_CENTER = "topCenter",
  TOP_RIGHT = "topRight",
  CENTER_LEFT = "centerLeft",
  CENTER_CENTER = "centerCenter",
  CENTER_RIGHT = "centerRight",
  BOTTOM_RIGHT = "bottomRight",
  BOTTOM_CENTER = "bottomCenter",
  BOTTOM_LEFT = "bottomLeft"
}
