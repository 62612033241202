import { styled, Box as MuiBox, Button as MuiButton } from "@mui/material";
import { ReactComponent as LaptopGraphics } from "assets/icons/laptop_wrapper.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DesktopButtonProps } from "./DesktopPositionsVariant.props";

export const Wrapper = styled(MuiBox)(() => {
  return `
    &.MuiBox-root {
      position: relative;
      width: 700px;
      height: 580px;
      margin: 0 auto;
    }
  `;
});

export const WrapperGraphics = styled(LaptopGraphics)(
  () => `
    top: 0;
    left: 0;
    width: 700px;
    height: 580px;
    position: absolute;
    pointer-events: none;
    z-index: 2;
  `
);

export const PositionsWrapper = styled(MuiBox)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiBox-root {
      position: absolute;
      margin: 0;
      padding: 0;
      width: 530px;
      height: 328px;
      top: 30px;
      left: 84px;
      display: flex;
      flex-flow: wrap;
      gap: 124px 1px;
      background-color: ${custom.background};
      z-index: 1;
    }
  `
);

export const ButtonWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      width: 176px;
      height: 24px;
    }
  `
);

export const Button = styled(({ selected, ...props }: DesktopButtonProps) => {
  const startIcon = selected ? <CheckCircleIcon /> : null;

  return <MuiButton {...props} startIcon={startIcon} />;
})(
  ({
    theme: {
      palette: { custom }
    },
    selected
  }) => {
    const backgroundColor = selected ? custom.turquoise.main : "transparent";

    return `
      &.MuiButton-root {
        width: 100%;
        color: ${custom.white.main};
        text-transform: none;
        background-color: ${backgroundColor};
        border: 1px solid ${custom.turquoise.light};
        border-radius: 12px;
        padding: 0;
        margin: 0;

        &:hover {
          background-color: ${custom.turquoise.main};
          border-color: ${custom.turquoise.main};
        }
      }
    `;
  }
);
