import { ActivationPopupsSensitivity } from "api/models";

export const DEFAULT_SENSITIVITY_TYPE = ActivationPopupsSensitivity.medium;

export const SENSITIVITY_TYPES: ActivationPopupsSensitivity[] = [
  ActivationPopupsSensitivity.low,
  ActivationPopupsSensitivity.lowMedium,
  ActivationPopupsSensitivity.medium,
  ActivationPopupsSensitivity.highMedium,
  ActivationPopupsSensitivity.high
];
