import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, FormProps } from "react-final-form";
import { SENSITIVITY_TYPES } from "config/constants";
import { Slider } from "shared/components";

import { SensitivityFormBaseProps } from "./SensitivityFormBase.props";
import { HintText, SliderWrapper } from "./SensitivityFormBase.styles";
import { getSliderMarks } from "./SensitivityFormBase.helpers";

import { SensitivityValues } from "../../types";
import { parseFormValueToSensitivity } from "../../utils";

export const SensitivityFormBase = ({ initialState, onSubmit }: SensitivityFormBaseProps) => {
  const { t } = useTranslation();

  const handleFormSubmit: FormProps<SensitivityValues, SensitivityValues>["onSubmit"] = useCallback(
    (values, form) => {
      if (values.sensitivity !== initialState.sensitivity) {
        return onSubmit(values, form);
      }
    },
    [initialState, onSubmit]
  );

  const getCurrentValueLabel = useCallback((value: number) => {
    const type = parseFormValueToSensitivity(value);
    return t(`settingsModule.sensitivity.form.${type}.label`);
  }, []);

  const getCurrentValueHint = useCallback((value: number) => {
    const type = parseFormValueToSensitivity(value);
    return t(`settingsModule.sensitivity.form.${type}.hint`);
  }, []);

  return (
    <Form
      initialValues={initialState}
      onSubmit={handleFormSubmit}
      render={({ handleSubmit }) => {
        return (
          <>
            <Field
              name="sensitivity"
              render={({ input: { value, onChange } }) => (
                <>
                  <SliderWrapper>
                    <Slider
                      value={value}
                      onChange={onChange}
                      onChangeCommitted={handleSubmit}
                      getAriaValueText={getCurrentValueLabel}
                      valueLabelFormat={getCurrentValueLabel}
                      valueLabelDisplay="auto"
                      marks={getSliderMarks()}
                      min={0}
                      max={100}
                      step={100 / (SENSITIVITY_TYPES.length - 1)}
                    />
                  </SliderWrapper>
                  <HintText>{getCurrentValueHint(value)}</HintText>
                </>
              )}
            />
          </>
        );
      }}
    />
  );
};
