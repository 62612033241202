import { useQueryClient, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { FormApi } from "final-form";
import { FormProps } from "react-final-form";
import { PutSensitivityRequest } from "api/models";
import { useNotifications, useQueryConfig, useSelectedShop } from "shared/hooks";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { putSensitivity } from "../../api";
import { SensitivityValues } from "../../types";
import { parseFormValueToSensitivity } from "../../utils";
import { throttle } from "lodash";

export const useUpdateSensitivity = () => {
  const { queryName } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const client = useQueryClient();

  const { mutateAsync: updateSensitivity } = useMutation<
    void,
    AxiosError<{ message: string }>,
    PutSensitivityRequest
  >(putSensitivity);

  const executeUpdateSensitivity: FormProps<SensitivityValues, SensitivityValues>["onSubmit"] =
    useCallback(
      throttle(
        async (values: SensitivityValues, form: FormApi<SensitivityValues, SensitivityValues>) => {
          try {
            await updateSensitivity({
              shopId: selectedShopId!,
              sensitivity: parseFormValueToSensitivity(values.sensitivity)
            });

            await client.invalidateQueries([queryName, selectedShopId]);

            form.reset();

            showNotification("settingsModule.sensitivity.successMessage", "success");
          } catch (error) {
            return formatError(error as NetworkError);
          }
        },
        2500
      ),
      [queryName, selectedShopId, showNotification]
    );

  return executeUpdateSensitivity;
};
