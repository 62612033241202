import { styled, Box as MuiBox } from "@mui/material";

export const Wrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 40px 0 32px;
    }
  `
);
