import { useCallback } from "react";
import { Button } from "@mui/material";
import { ButtonGroup } from "shared/components";
import { DeviceType } from "shared/types";
import { PositionsModeControlProps } from "./PositionsModeControl.props";
import { Wrapper } from "./PositionsModeControl.styles";

export const PositionsModeControl = ({ device, onChange }: PositionsModeControlProps) => {
  const handleDisplayStateChange = useCallback(
    (value: DeviceType) => () => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Wrapper>
      <ButtonGroup variant="contained">
        {Object.values(DeviceType).map((key) => (
          <Button
            color={device === key ? "success" : "primary"}
            key={key}
            onClick={handleDisplayStateChange(key as DeviceType)}
            disabled={device === key}
          >
            {key}
          </Button>
        ))}
      </ButtonGroup>
    </Wrapper>
  );
};
