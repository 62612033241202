import { TemplatePosition } from "shared/types";

export const questionnaireNewsletterFormsPositionsConfig: TemplatePosition[] = [
  TemplatePosition.TOP_LEFT,
  TemplatePosition.TOP_CENTER,
  TemplatePosition.TOP_RIGHT,
  TemplatePosition.CENTER_LEFT,
  TemplatePosition.CENTER_CENTER,
  TemplatePosition.CENTER_RIGHT,
  TemplatePosition.BOTTOM_LEFT,
  TemplatePosition.BOTTOM_CENTER,
  TemplatePosition.BOTTOM_RIGHT
];
