import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";
import { DescriptionText, Heading, Subheading } from "shared/components";
import { DeviceType } from "shared/types";
import { useNewsletterFormsPositionsStep } from "../../../hooks";
import { ErrorText, StepWrapper, StepsContent } from "../../StepStyles";
import { NavigationButtons } from "../../NavigationButtons";
import { MobileStepper } from "../../Stepper";
import { PositionsModeControl } from "./PositionsModeControl";
import { PositionsCounter } from "./PositionsCounter";
import { DesktopPositionsVariant, MobilePositionsVariant } from "./PositionsVariant";
import { newsletterFormsPositionsValidator } from "./NewsletterFormsPositions.validation";
import { FORM_ERROR } from "final-form";

export const NewsletterFormsPositions = () => {
  const { t } = useTranslation();

  const { data, metadata, hasPrev, handlePrevStep, executeSubmitNewsletterFormsPositions } =
    useNewsletterFormsPositionsStep();

  const [device, setDevice] = useState<DeviceType>(DeviceType.DESKTOP);

  const availablePositions = useMemo(() => metadata[device], [metadata, device]);

  return (
    <Form
      initialValues={data}
      onSubmit={executeSubmitNewsletterFormsPositions}
      validate={newsletterFormsPositionsValidator(metadata)}
      render={({ values, handleSubmit, submitting, errors }) => (
        <StepWrapper>
          <StepsContent>
            <Heading>{t("questionnaireModule.steps.design.title")}</Heading>
            <Subheading>
              {t("questionnaireModule.steps.design.newsletterFormsPositions.title")}
            </Subheading>
            <DescriptionText>
              {t("questionnaireModule.steps.design.newsletterFormsPositions.description")}
            </DescriptionText>
            <PositionsModeControl device={device} onChange={setDevice} />
            <PositionsCounter positions={values[device]} availablePositions={availablePositions} />
            {device === DeviceType.DESKTOP ? (
              <DesktopPositionsVariant availablePositions={availablePositions} />
            ) : (
              <MobilePositionsVariant availablePositions={availablePositions} />
            )}
            {errors?.[FORM_ERROR] && <ErrorText>{t(errors[FORM_ERROR])}</ErrorText>}
            <NavigationButtons
              prevDisabled={!hasPrev}
              nextLoading={submitting}
              onNextClick={handleSubmit}
              onPrevClick={handlePrevStep}
            />
          </StepsContent>
          <MobileStepper
            prevDisabled={!hasPrev}
            nextLoading={submitting}
            onNextClick={handleSubmit}
            onPrevClick={handlePrevStep}
          />
        </StepWrapper>
      )}
    />
  );
};
