import { apiClient } from "shared/utils";
import { QuestionnaireNewsletterFormsPositionsStepData, ShopIdParam } from "api/models";

export const putSubmitNewsletterFormsPositions = async ({
  shopId,
  ...values
}: QuestionnaireNewsletterFormsPositionsStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/newsletter-position`, values);

  return data;
};
