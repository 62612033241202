import {
  QuestionnaireNewsletterFormsPositionsStepData,
  QuestionnaireNewsletterFormsPositionsStepMetadata
} from "api/models";
import { QuestionnaireNewsletterFormsPositionsFormMetadataState } from "../../types";

export const NEWSLETTER_FORMS_POSITIONS: QuestionnaireNewsletterFormsPositionsStepData = {
  desktop: {
    topRight: false,
    topCenter: false,
    topLeft: false,
    centerRight: true,
    centerCenter: false,
    centerLeft: true,
    bottomRight: true,
    bottomCenter: true,
    bottomLeft: true
  },
  mobile: {
    topRight: false,
    topCenter: false,
    topLeft: false,
    centerRight: true,
    centerCenter: false,
    centerLeft: true,
    bottomRight: false,
    bottomCenter: true,
    bottomLeft: false
  }
};

export const parseNewsletterFormsPositionsFormMetadata = (
  metadata: QuestionnaireNewsletterFormsPositionsStepMetadata | undefined
): QuestionnaireNewsletterFormsPositionsFormMetadataState => {
  const fields = metadata?.fields;

  return {
    desktop: {
      topRight: !fields?.desktop.topRight.disabled,
      topCenter: !fields?.desktop.topCenter.disabled,
      topLeft: !fields?.desktop.topLeft.disabled,
      centerRight: !fields?.desktop.centerRight.disabled,
      centerCenter: !fields?.desktop.centerCenter.disabled,
      centerLeft: !fields?.desktop.centerLeft.disabled,
      bottomLeft: !fields?.desktop.bottomLeft.disabled,
      bottomRight: !fields?.desktop.bottomRight.disabled,
      bottomCenter: !fields?.desktop.bottomCenter.disabled
    },
    mobile: {
      topRight: !fields?.mobile.topRight.disabled,
      topCenter: !fields?.mobile.topCenter.disabled,
      topLeft: !fields?.mobile.topLeft.disabled,
      centerRight: !fields?.mobile.centerRight.disabled,
      centerCenter: !fields?.mobile.centerCenter.disabled,
      centerLeft: !fields?.mobile.centerLeft.disabled,
      bottomLeft: !fields?.mobile.bottomLeft.disabled,
      bottomRight: !fields?.mobile.bottomRight.disabled,
      bottomCenter: !fields?.mobile.bottomCenter.disabled
    }
  };
};
