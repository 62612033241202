import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";
import { questionnaireNewsletterFormsPositionsConfig } from "../../../../../config";
import { DesktopPositionsVariantProps } from "./DesktopPositionsVariant.props";
import {
  Button,
  ButtonWrapper,
  PositionsWrapper,
  Wrapper,
  WrapperGraphics
} from "./DesktopPositionsVariant.styles";

export const DesktopPositionsVariant = ({ availablePositions }: DesktopPositionsVariantProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <WrapperGraphics />
      <PositionsWrapper>
        {questionnaireNewsletterFormsPositionsConfig.map((position) => (
          <ButtonWrapper key={`desktop.${position}`}>
            <Field
              name={`desktop.${position}`}
              render={({ input: { value, onChange } }) =>
                availablePositions[position] ? (
                  <Button selected={value} onClick={() => onChange(!value)}>
                    {t(
                      `questionnaireModule.steps.design.newsletterFormsPositions.form.${position}`
                    )}
                  </Button>
                ) : null
              }
            />
          </ButtonWrapper>
        ))}
      </PositionsWrapper>
    </Wrapper>
  );
};
